<template>
  <div class="bg-evaluation center fullheight">
    <div class="head">
      <!-- <Icon color="white" class="icon-evaluation">
        <EqualizerRound />
      </Icon> -->
     <img class="icon-evaluation" src="@/assets/images/svg/evaluationcount.svg" />
    </div>
    <div class="wrap fullwidth center">
      <div class="count-evaluation"><NumberCounter :value="count" class="text-color-blue nums"></NumberCounter></div>
      <div>本周评价量</div>
    </div>
  </div>
</template>

<script>
// import { EqualizerRound } from '@v2icons/material'
// import { Icon } from '@v2icons/utils'
import NumberCounter from './Counter'
export default {
  components: {
    NumberCounter
    // Icon,
    // EqualizerRound
  },
  data () {
    return {
      count: 0,
      timer: undefined
    }
  },
  props: {},
  mounted () {
    this.getEvaluationCount()
    this.timer = setInterval(() => {
      this.getEvaluationCount()
    }, 10000)
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getEvaluationCount () {
      this.$api.post(
        'v1/schoolintegrateddata/GetEvaluationCount',
        {
          SchoolId: this.$store.getters.schoolData.Id
        },
        (r) => {
          this.count = r.Target ? r.Target : 0
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-evaluation {
  // margin: 1.5625rem;
  flex-direction: column;
  // height: calc(100% - 1.5625rem * 2);
  position: relative;
}
.head {
  background: linear-gradient(60deg, #ffa726, #fb8c00);
  box-shadow: 0 .625rem 1.041667rem -0.520833rem rgb(233 30 99 / 28%),
    0 .208333rem 1.041667rem 0 rgb(0 0 0 / 12%), 0 .364583rem .416667rem -0.260417rem rgb(233 30 99 / 20%);
  // margin-bottom: 0.78125rem;
  border-radius: .78125rem;
  position: absolute;
  left: -1.041667rem;
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.wrap {
  flex: 1;
  overflow: hidden;
  font-size: .9375rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  flex-direction: column;
}
.icon-evaluation {
  // font-size: 3.385417rem;
  width: 3.125rem;
}
.count-evaluation {
  font-size: 3.385417rem;
}
</style>
