<template>
  <div class="bg-quality center">
    <div class="head center fullwidth">
        <div class="adorn"></div>
        <div class="title">综合素养</div>
        <div class="adorn"></div>
      </div>
      <div class="wrap fullwidth" ref="radarChart">
        <el-empty class="fullheight fullheight" v-if="!grades||grades.length===0" description="暂无内容"></el-empty>

      </div>
      <div class="center tooltip fullwidth">
        <el-tag style="padding: 0px 2px;" size="mini" :effect="gradeId===item.id?'dark':'plain'" v-for="item in grades" :key="item.id">{{item.name}}</el-tag>
      </div>
  </div>
</template>

<script>
import 'default-passive-events'
export default {
  data () {
    return {
      quality: { Dimensions: [], QualityList: [] },
      gradeId: undefined,
      grades: [],
      timer: undefined,
      timeSpan: 5000,
      radarChart: undefined
    }
  },
  props: {},
  mounted () {
    this.$nextTick(() => {
      this.getData().then(() => {
        if (this.$refs.radarChart) {
          this.loop()
        }
      })
    })
  },
  methods: {
    getData () {
      return new Promise(resolve => {
        this.$api.post(
          'v1/schoolintegrateddata/Quality',
          {
            SchoolId: this.$store.getters.schoolData.Id
          },
          (r) => {
            this.quality = r.Target ? r.Target : this.quality
            this.quality.QualityList = this.quality.QualityList.filter(c => c.GradeId)
            const orgs = this.$store.getters.orgsData().filter(c => this.quality.QualityList.map(item => item.GradeId).includes(c.Id))
            this.grades = orgs.map(c => ({ id: c.Id, name: c.Name, number: c.Number, gradeNumber: c.GradeNumber })).sort(this.compare('gradeNumber', 'asc'))
            resolve()
          }
        )
      })
    },
    drawRadar (index, raderSchoolData) {
      if (this.radarChart) {
        this.radarChart.dispose()
      }

      this.radarChart = this.$echarts.init(this.$refs.radarChart)
      const scores = this.quality.QualityList[index].DimensionsScore
      const schoolDataMaxValue = Math.max(...raderSchoolData)
      const gradeDataMaxValue = Math.max(...scores.map(c => Math.abs(c.Score)))
      const maxValue = (schoolDataMaxValue > gradeDataMaxValue ? schoolDataMaxValue : gradeDataMaxValue) * 1.1
      const indicator = this.quality.Dimensions.map(c => ({ name: c.Name, id: c.Id, max: maxValue }))
      const raderGradeData = []
      for (const item of indicator) {
        const dimenson = scores.find(c => c.DimensionId === item.id)
        raderGradeData.push(dimenson ? Math.abs(dimenson.Score) : 0)
      }
      // 绘制图表
      this.radarChart.setOption({
        legend: {
          left: 'auto',
          orient: 'vertical',
          top: '5%',
          data: ['年级', '全校']
        },
        radar: {
          axisName: {
            color: '#002a7a',
            backgroundColor: '#F5F6FA',
            borderRadius: 5,
            padding: [6, 6],
            fontSize: 14,
            fontFamily: 'AlibabaPuHuiTiM',
            formatter: function (text) {
              var strlength = text.length
              if (strlength % 4 !== 0) {
                text = text.replace(/\S{4}/g, function (match) {
                  return match + '\n'
                })
              } else {
                text = text.replace(/\S{4}/g, function (match) {
                  return match + '\n'
                })
                strlength = text.length
                text = text.substring(0, strlength - 1)
              }
              return text
            }
          },
          indicator: indicator,
          center: ['50%', '55%'],
          radius: '60%'
        },
        series: [{
          type: 'radar',
          data: [
            {
              value: raderGradeData,
              name: '年级'
            },
            {
              value: raderSchoolData,
              name: '全校'
            }
          ]
        }]
      })
    },
    loop () {
      let index = 0
      const limit = this.grades.length
      this.gradeId = this.grades[index].id
      const allScores = this.quality.QualityList.reduce((prev, item) => {
        return prev.concat(item.DimensionsScore)
      }, [])
      const indicator = this.quality.Dimensions.map(c => ({ name: c.Name, id: c.Id }))
      const raderSchoolData = []
      for (const item of indicator) {
        const arrays = allScores.filter(c => c.DimensionId === item.id)
        raderSchoolData.push(arrays.reduce((prev, item) => {
          return prev + Math.abs(item.Score)
        }, 0) / (arrays.length > 0 ? arrays.length : 1))
      }
      this.drawRadar(index, raderSchoolData)
      this.timer = setInterval(() => {
        if (index < limit - 1) {
          index++
        } else {
          index = 0
        }
        this.gradeId = this.grades[index].id
        this.drawRadar(index, raderSchoolData)
      }, this.timeSpan)
    },
    compare (attr, sortType) {
      if (!['asc', 'desc'].includes(sortType)) {
        sortType = 'asc'
      }
      return function (a, b) {
        a = isNaN(a[attr]) ? parseInt(a[attr]) : a[attr]
        b = isNaN(b[attr]) ? parseInt(b[attr]) : b[attr]
        if (sortType === 'asc') {
          return a - b
        } else {
          return b - a
        }
      }
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-quality {
  margin: 1.5625rem;
  flex-direction: column;
  height: calc(100% - 1.5625rem * 2);
}
.head {
  height: 1.822917rem;
  margin-bottom: .78125rem;
}
.wrap {
  flex:1;
  overflow: hidden;
}
.el-tag+.el-tag{
  margin-left: .260417rem;
}
.el-tag{
  margin-bottom: .260417rem;
}
.tooltip{
  flex-wrap:wrap;
}
</style>
