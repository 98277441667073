<template>
<div>
    <span ref="numberGrow" :data-time="time">0</span>
</div>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tiemr: undefined
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$refs.numberGrow) {
        this.numberGrow(this.$refs.numberGrow)
      }
    })
  },
  methods: {
    numberGrow (ele) {
      const step = parseInt((this.value) / (this.time * 100))
      let current = 0
      let start = 0
      const span = this.value >= 50 ? parseInt(this.value / 50) : 1
      this.tiemr = setInterval(() => {
        start += step + span
        if (start >= this.value) {
          clearInterval(this.tiemr)
          start = this.value
          this.tiemr = undefined
        }
        if (current === start) {
          return
        }
        current = start
        ele.innerHTML = current.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
      }, 10)
    }
  },
  watch: {
    value (val) {
      if (this.$refs.numberGrow && val > 0) {
        this.numberGrow(this.$refs.numberGrow)
      }
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
