<template>
  <div class="center" style="padding: .729167rem 0 0 2.1875rem">
    <div class="cicon"></div>
    <div class="label1">{{date | DateFormat("yyyy/MM/dd")}}</div>
    <div class="label2">{{weekday[date.getDay()]}}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      date: new Date(),
      timer: undefined
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.date = new Date()
    }, 1000)
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style lang="scss" scoped>
.cicon {
  width: 1.458333rem;
  height: 1.458333rem;
  background: url(../../../assets/images/date.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.label1 {
  font-size: 1.041667rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #ffffff;
  padding: .3125rem 0 .3125rem .885417rem;
}
.label2 {
  font-size: 1.145833rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #ffffff;
  padding: .208333rem 0 .208333rem .833333rem;
}
</style>
