<template>
  <div class="bg-wonderfulmoment">
    <div class="bg">
      <div class="head center">
        <div class="adorn"></div>
        <div class="title">精彩瞬间</div>
        <div class="adorn"></div>
      </div>
      <div class="wrap">
        <ScrollLeft :minLimit="2" :offSetX="20.677" :sourceLength="datas.length">
          <div class="content">
            <img
              v-for="(item, index) of datas.concat(
                datas.length > 2 ? datas.slice(0, 2) : []
              )"
              :key="index"
              class="item"
              :src="item.Path"
              alt=""
              :data-type='1'
              v-real-img="item.Path"
            />
          </div>
        </ScrollLeft>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollLeft from '@/components/Scroll/ScrollLeft'
export default {
  components: {
    ScrollLeft
  },
  data () {
    return {
      datas: []
    }
  },
  props: {},
  created () {
    this.getData()
    setInterval(() => {
      this.getData()
    }, 1000 * 60 * this.$store.getters.dataTimeSpan)
  },
  methods: {
    getData () {
      this.$api.post(
        'v1/classintegrateddata/WonderfulMoment',
        {
          SchoolId: this.$store.getters.schoolData.Id,
          DataCount: 20,
          ClassId: this.$store.getters.classData.Id
        },
        (r) => {
          this.datas = r.Target
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-wonderfulmoment {
  padding: 1.041667rem 0 0 1.041667rem;
}
.bg {
  width: 43.4375rem;
  height: 20.833333rem;
  background: #ffffff;
  border-radius: 1.5625rem;
}
.head {
  height: 1.875rem;
  padding-top: 1.770833rem;
}
.title {
  height: 1.302083rem;
  font-size: 1.354167rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  margin: 0 .677083rem .572917rem .677083rem;
}
.content {
  display: inline-flex;
}
.wrap {
  width: 40.364583rem;
  display: inline-block;
  height: 19.427083rem;
  margin: 2.03125rem 1.5625rem 1.979167rem 1.5625rem;
  overflow: hidden;
}
.item {
  width: 19.6875rem;
  height: 13.59375rem;
  border-radius: .520833rem;
  margin-right: .989583rem;
  background-repeat: no-repeat;
  background-size: cover;
  // object-fit:fill;
  object-fit:cover;
}
</style>
