var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-personhonor center"},[_vm._m(0),_c('div',{staticClass:"wrap fullwidth"},[(!_vm.list.Orgs||_vm.list.Orgs.length===0)?_c('el-empty',{staticClass:"fullwidth fullheight",attrs:{"description":"暂无内容"}}):_vm._l((_vm.list.Orgs),function(grade,index){return _c('div',{key:index,class:[
        'grade center',
        (index + 1) % 3 !== 0 ? 'border-right' : '',
        parseInt(index / 3) > 0 ? 'border-top' : '',
      ]},[(grade)?_c('div',{staticClass:"grade-name"},[_vm._v(_vm._s(grade.Name))]):_vm._e(),(grade)?_c('div',{staticClass:"fullwidth",staticStyle:{"overflow":"hidden"}},[_c('ScrollUp',{attrs:{"minLimit":3,"offSetY":2.135417,"sourceLength":_vm.studentsData(grade).length}},_vm._l((_vm.studentsData(grade).concat(
              _vm.studentsData(grade).length > 3
                ? _vm.studentsData(grade).slice(0, 3)
                : []
            )),function(item,i){return _c('div',{key:i,staticClass:"student-item center"},[_c('div',{staticClass:"student-photo center"},[_c('img',{directives:[{name:"real-img",rawName:"v-real-img",value:(item.Photo),expression:"item.Photo"}],attrs:{"src":_vm.defaultPhoto(item.Gender)}}),_c('div',{staticClass:"center student-name"},[_c('div',{staticClass:"name-text"},[_vm._v(_vm._s(item.MemberName))]),_c('div',{staticClass:"class-text"},[_vm._v(" "+_vm._s(grade.ChidOrgList.find((c) => c.Id === item.OrgId) ? grade.ChidOrgList.find((c) => c.Id === item.OrgId) .Name : "")+" ")])])]),_c('div',{staticClass:"student-content center"},_vm._l((item.GrowthSystemList),function(growth,j){return _c('img',{key:j,attrs:{"src":growth.Logo}})}),0)])}),0)],1):_vm._e()])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head center fullwidth"},[_c('div',{staticClass:"adorn"}),_c('div',{staticClass:"title"},[_vm._v("个人光荣榜")]),_c('div',{staticClass:"adorn"})])
}]

export { render, staticRenderFns }