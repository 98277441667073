<template>
  <div class="bg-classhonor">
    <div class="bg">
      <div class="head center">
        <div class="adorn"></div>
        <div class="title">班级光荣榜</div>
        <div class="adorn"></div>
      </div>
      <div class="wrap">
        <ScrollUp
          :minLimit="5"
          :offSetY="4.0625"
          :sourceLength="list.length"
        >
        <div class="content">
          <div v-for="(item, index) of list.concat(
                          list.length > 5
                            ? list.slice(0, 5)
                            : []
                        )" :key="index" class="class">
            <div class="class-name">{{ item.Name }}</div>
            <div class="class-score-wrap">
              <div v-for="(df,i) of item.GrowthDatas" :key="i" class="class-score">
                <div class="class-score-basic">
                  <img
                    :src="df.JCDJTB"
                    :class="df.BZJJZT?'':'decolorize'"
                  />
                  <div class="class-score-count">X{{df.DBCS}}</div>
                </div>
                <div v-if="df.BZJJZT&&df.JJTB" class="class-score-basic">
                  <img
                    :src="df.JJTB"
                  />
                  <!-- <div class="class-score-count">X2</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        </ScrollUp>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      weekNumber: 0,
      list: []
    }
  },
  props: {},
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getData()
      setInterval(() => {
        this.getData()
      }, 1000 * 60 * this.$store.getters.dataTimeSpan)
    },
    getData () {
      this.$api.post(
        'v1/classintegrateddata/GetClassHonorRoll',
        {
          SchoolId: this.$store.getters.schoolData.Id,
          GradeNumber: this.$store.getters.classData.GradeNumber,
          TermYearStr: this.$store.getters.term.SSXN,
          TermType: this.$store.getters.term.XQLX
        },
        (r) => {
          const data = r.Target
          if (data) {
            this.list = data.ClassDataList
            this.weekNumber = data.MaxWeekNumber
            const classDatas = this.$store.getters.orgsData().reduce((prev, item) => {
              return prev.concat(
                item.ChidOrgList
              )
            }, [])
            for (const item of this.list) {
              const classData = classDatas.find(c => c.Id === item.BJId)
              item.Name = classData.Name
            }
          }
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-classhonor {
  padding-left: 1.041667rem;
}
.bg {
  width: 30rem;
  height: 26.666667rem;
  background: #ffffff;
  border-radius: 1.5625rem;
}
.head {
  height: 1.875rem;
  padding-top: 1.770833rem;
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.wrap {
  display: inline-block;
  height: 19.427083rem;
  margin: 2.03125rem 1.5625rem 0rem 1.5625rem;
  overflow: hidden;
}
.title {
  height: 1.302083rem;
  font-size: 1.354167rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  margin: 0 .677083rem .572917rem .677083rem;
}
.class {
  width: 26.875rem;
  height: 3.177083rem;
  background: url(../../assets/images/classBG.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: .885417rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.class-name {
  width: 7.552083rem;
  font-size: 1.145833rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  padding-left: 1.354167rem;
}
.class-score-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: .520833rem;
}
.class-score {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: .520833rem;
}
.class-score-basic {
  img {
    width: 1.25rem;
    height: 1.25rem;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.class-score-promotion {
  img {
    width: 1.25rem;
    height: 1.25rem;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.class-score-count {
  font-size: 1.145833rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
}
</style>
