const state = {
  token: undefined,
  students: undefined,
  indexs: undefined,
  schoolData: undefined,
  orgsData: undefined,
  classData: undefined,
  term: undefined,
  courseTable: undefined,
  dataTimeSpan: 20,
  defaultBoyPicture: 'assets/images/DefaultPhotoBoy.png',
  defaultGirlPicture: 'assets/images/DefaultPhotoGirl.png',
  defaultImage: '',
  defaultClassImage: 'assets/images/DefaultPImageClass.png',
  apipath: undefined,
  apipath2: '/aliyun60@5000'
}
const getters = {
  token: (state) => {
    return state.token
  },
  defaultPhoto: (state) => (gender) => {
    return gender === 2 ? state.defaultGirlPicture : state.defaultBoyPicture
  },
  defaultClassImage: (state) => {
    return state.defaultClassImage
  },
  apipath: (state) => {
    // return process.env.NODE_ENV === 'development'
    //   ? '/debugger' : state.apipath
    return state.apipath
  },
  apipath2: (state) => {
    return state.apipath2
  },
  orgsData: (state) => (orgId) => {
    const orgs = state.orgsData ? state.orgsData : []
    if (orgId) {
      const org = orgs.find(c => c.Id === orgId)
      return org ? org.ChidOrgList : []
    }
    return state.orgsData ? state.orgsData : []
  },
  classData: (state) => {
    return state.classData || undefined
  },
  schoolData: (state) => {
    return state.schoolData || undefined
  },
  indexs: (state) => {
    return state.indexs ? state.indexs : []
  },
  students: (state) => {
    return state.students ? state.students : []
  },
  terms: (state) => {
    return state.term || undefined
  },
  term: (state) => {
    return state.term ? (state.term.find((c) => c.IsCurrent === 1) ? state.term.find((c) => c.IsCurrent === 1) : undefined) : undefined
  },
  dataTimeSpan: (state) => {
    return state.dataTimeSpan
  },
  courseTable: (state) => {
    return state.courseTable
  }
}
const mutations = {
  SETAPIPATH (state, data) {
    state.apipath = data || undefined
  },
  SETORGSDATA (state, data) {
    state.orgsData = data || []
  },
  SETCLASSDATA (state, data) {
    state.classData = data || []
  },
  SETSCHOOLDATA (state, data) {
    state.schoolData = data || undefined
  },
  SETINDEXS (state, data) {
    state.indexs = data || []
  },
  SETSTUDENTS (state, data) {
    state.students = data || []
  },
  SETTERM (state, data) {
    state.term = data || undefined
  },
  SETCOURSETABLE (state, data) {
    state.courseTable = data || undefined
  },
  SETTOKEN (state, data) {
    state.token = data || undefined
  }
}

const actions = {
  setApiPath (context, data) {
    return new Promise(resolve => {
      context.commit('SETAPIPATH', data)
      resolve()
    })
  },
  setOrgsData (context, data) {
    return new Promise(resolve => {
      context.commit('SETORGSDATA', data.orgs)
      if (data.classCode) {
        const rxn = Number(data.classCode.substr(0, 4))
        const bh = Number(data.classCode.substr(4, 2))
        const grade = data.orgs.find(c => c.RXN.includes(rxn))
        const classdata = grade.ChidOrgList.find(c => c.Number === bh)
        context.commit('SETCLASSDATA', classdata)
      }
      resolve()
    })
  },
  setSchoolData (context, data) {
    return new Promise(resolve => {
      context.commit('SETSCHOOLDATA', data)
      resolve()
    })
  },
  setIndexs (context, data) {
    return new Promise(resolve => {
      context.commit('SETINDEXS', data)
      resolve()
    })
  },
  setStudents (context, data) {
    return new Promise(resolve => {
      context.commit('SETSTUDENTS', data)
      resolve()
    })
  },
  setTerm (context, data) {
    return new Promise(resolve => {
      context.commit('SETTERM', data)
      resolve()
    })
  },
  setCourseTable (context, data) {
    return new Promise(resolve => {
      context.commit('SETCOURSETABLE', data)
      resolve()
    })
  },
  setToken (context, data) {
    return new Promise(resolve => {
      context.commit('SETTOKEN', data)
      resolve(data)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
