import store from '@/store/index.js'
import Vue from 'vue'
import { Message } from 'element-ui'
import { sha1 } from '@/utils/common'
Vue.component(Message)
Vue.prototype.$message = Message
// 配置API接口地址

// 引用axios
var axios = require('axios')
// 自定义判断元素类型JS
function toType (obj) {
  return {}.toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase()
}
// 参数过滤函数
function filterNull (o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key]
    }
    if (toType(o[key]) === 'string') {
      o[key] = o[key].trim()
    } else if (toType(o[key]) === 'object') {
      o[key] = filterNull(o[key])
    } else if (toType(o[key]) === 'array') {
      o[key] = filterNull(o[key])
    }
  }
  return o
}

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.data.token = store.getters.token
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)
// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log(error)
    if (error.response) {
      if (error.response.status !== 500) {
        console.log(error)
        Vue.prototype.$message({
          type: 'error',
          duration: 2000,
          message: '数据接口错误,请联系管理员',
          closable: true
        })
      } else {
        return Promise.reject(error) // 返回接口返回的错误信息
      }
    }
  }
)
/*
  接口处理函数
*/
function apiAxios (method, url, params, success, failure, rootUrl) {
  const root = rootUrl || store.getters.apipath
  if (params) {
    params = filterNull(params)
    params.token = store.getters.token
  }
  axios({
    method: method,
    url: url,
    data: method === 'POST' || method === 'PUT' ? params : null,
    params: method === 'GET' || method === 'DELETE' ? params : null,
    baseURL: root,
    withCredentials: false,
    timeout: 1000 * 60 * 5
  })
    .then(
      res => {
        if (res) {
          if (success && res.data.Code === 0) {
            success(res.data)
          } else {
            if (res.data.Code === -1) {
              const timesTamp = new Date().getTime()
              const Nonce = Math.random()
              // const network = require('os').networkInterfaces()
              // const AppId = network['以太网'] ? network['以太网'][0].mac : network['本地连接'] ? network['本地连接'][0].mac : 'NotFoundIpAddress'
              const AppId = 'digitalshow'
              const AppName = '大屏展示'
              const signature = sha1(store.getters.appSecret + timesTamp + Nonce)
              apiAxios('POST', 'v1/home/GetTokenBySignature', { Signature: signature, TimesTamp: timesTamp + '', Nonce: Nonce + '', AppId: AppId, AppName: AppName }, (tokenResponse) => {
                if (tokenResponse.Code === 0) {
                  store.dispatch('setToken', tokenResponse.Target).then((result) => {
                    params.token = result
                    apiAxios(method, url, params, success, failure, rootUrl)
                  })
                }
              }, failure, rootUrl)
            } else if (failure) {
              failure(res.data)
            } else {
              Vue.prototype.$message({
                type: 'error',
                duration: 2000,
                message: res.data.Message,
                closable: true
              })
            }
          }
        }
      }
    )
    .catch(
      err => {
        if (err) {
          process.env.NODE_ENV === 'development' ? console.log(err)
            : Vue.prototype.$message({
              type: 'error',
              duration: 2000,
              message: '数据接口错误,请联系管理员',
              closable: true
            })
        }
      }
    )
}
// 返回在vue模板中的调用接口
export default {
  // get: function (url, params, success, failure, rootUrl) {
  //   return apiAxios('GET', url, params, success, failure, rootUrl)
  // },
  post: function (url, params, success, failure, rootUrl) {
    return apiAxios('POST', url, params, success, failure, rootUrl)
  }
  // put: function (url, params, success, failure, rootUrl) {
  //   return apiAxios('PUT', url, params, success, failure, rootUrl)
  // },
  // delete: function (url, params, success, failure, rootUrl) {
  //   return apiAxios('DELETE', url, params, success, failure, rootUrl)
  // }
}
