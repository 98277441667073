<template>
  <div class="bg-evaluationrecord center">
    <div class="head center fullwidth">
      <div class="adorn"></div>
      <div class="title">评价点滴</div>
      <div class="adorn"></div>
    </div>
    <div class="wrap fullwidth">
      <el-empty
        class="fullwidth fullheight"
        v-if="list.length === 0"
        description="暂无内容"
      ></el-empty>
      <div
        v-else
        :class="['activity center', i !== 2 ? 'border-right' : '']"
        v-for="(activity, i) in list"
        :key="i"
      >
        <div v-if="activity" class="activity-name">
          {{ activity.ActivityName }}
        </div>
        <div
          v-if="activity"
          class="fullwidth record center"
          style="overflow: hidden"
        >
          <ScrollUp
            :minLimit="3"
            :offSetY="3.75"
            :sourceLength="activity.Records.length"
          >
            <div
              class="record-item center"
              v-for="(item, index) in activity.Records.concat(
                activity.Records.length > 3 ? activity.Records.slice(0, 3) : []
              )"
              :key="index"
            >
              <img v-real-img="item.Photo" :src="defaultPhoto(item.Gender)" />
              <div class="record-content center">
                <div class="record-index center">
                  <div>{{ item.IndexName }}</div>
                  <el-badge
                    class="record-index-score"
                    :value="itemScore(item.Score)"
                    :type="item.Score < 0 ? 'danger' : 'success'"
                  >
                  </el-badge>
                </div>
                <div class="record-description">
                  {{
                    item.StudentName +
                    formatDateStr(item.EvaluationTime) +
                    "在" +
                    item.CourseName +
                    "课上获得"
                  }}
                </div>
              </div>
            </div>
          </ScrollUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/common.js'
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      list: []
    }
  },
  props: {},
  created () {
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/schoolintegrateddata/getEvaluationPreview',
          {
            SchoolId: this.$store.getters.schoolData.Id,
            DataCount: 80
          },
          (r) => {
            this.list = r.Target ? r.Target : this.list
            const num = 3 - this.list.length
            for (let i = 0; i < num; i++) {
              this.list.push(undefined)
            }
            resolve()
          }
        )
      })
    }
  },
  computed: {
    itemScore () {
      return (score) => {
        return score > 0 ? '+' + score : score + ''
      }
    },
    defaultPhoto () {
      return (gender) => {
        return require('@/' + this.$store.getters.defaultPhoto(gender))
      }
    },
    formatDateStr () {
      return (date) => {
        const newdate = new Date(date.replace(/-/g, '/').replace('T', ' '))
        return formatDate(newdate, 'hh:mm')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-evaluationrecord {
  margin: 1.5625rem;
  flex-direction: column;
  height: calc(100% - 1.5625rem * 2);
}
.head {
  flex: 1;
}
.wrap {
  height: 15.15625rem;
  margin-top: 0.78125rem;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.activity {
  width: calc(100% / 3 - 4px);
  height: 100%;
  box-sizing: border-box;
  border: 0.125rem none #002a7a;
  overflow: hidden;
  justify-content: flex-start;
  flex-direction: column;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  .activity-name {
    background: #f5f6fa;
    border-radius: 0.520833rem;
    padding: 0.78125rem;
    margin-top: 0.520833rem;
    margin-bottom: 0.78125rem;
  }
}
.record {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  .record-item {
    margin: 0 1.041667rem;
    justify-content: flex-start;
    height: 3.75rem;
    img {
      height: 2.604167rem;
      width: 2.604167rem;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
    }
    .record-content {
      height: inherit;
      margin-left: 0.520833rem;
      align-items: flex-start;
      flex-direction: column;
      .record-index {
        font-size: 0.9375rem;
        justify-content: flex-start;
        .record-index-score {
          margin-left: 0.260417rem;
        }
      }
      .record-description {
        font-size: 0.729167rem;
        color: #919292;
      }
    }
  }
}

.border-right {
  border-right-style: dotted;
}
</style>
