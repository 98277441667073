<template>
  <div class="center" style="padding: 0.729167rem 0 0 2.083333rem">
    <div class="cicon"></div>
    <div class="label">{{ date | DateFormat("hh:mm") }}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      date: new Date(),
      timer: undefined
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.date = new Date()
    }, 1000)
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
<style lang="scss" scoped>
.cicon {
  width: 1.458333rem;
  height: 1.458333rem;
  background: url(../../../assets/images/clock.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.label {
  font-size: 1.145833rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #ffffff;
  padding: 0.3125rem 0 0.3125rem 0.78125rem;
}
</style>
