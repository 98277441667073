<template>
  <div class="center" style="position: relative">
    <div class="bg"></div>
    <div class="label">{{$store.getters.classData.Name}}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.bg {
  width: 12.34375rem;
  height: 2.916667rem;
  background: #ffffff;
  opacity: 0.2;
  border-radius: 1.458333rem;
}
.label {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  margin:auto;
  height: 1.614583rem;
  font-size: 1.666667rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.510417rem;
}
</style>
