<template>
  <div>
    <div class="scroll-content" :style="{ top, transition }">
      <slot>
      </slot>
      <!-- <div v-for="(item,index) in items.concat(itemsLength>minLimit?items:[])" :key="index">{{ item }}</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      itemsLength: 0,
      itemsIndex: 0,
      timeSpan: 0
    }
  },
  props: {
    dataCount: {
      type: Number,
      default: 0
    },
    sourceLength: {
      type: Number,
      default: 0
    },
    minLimit: {
      type: Number,
      default: Infinity
    },
    offSetY: {
      type: Number,
      default: 0
    },
    offSetValue: {
      type: Number,
      default: 1
    }
  },
  mounted () {
    this.getdata()
    this.scroll()
  },
  methods: {
    scroll () {
      if (this.dataCount > 0) {
        this.timeSpan = Math.floor(Math.random() * this.dataCount * 1000)
      }
      setTimeout(() => {
        setInterval(() => {
          if (
            this.itemsIndex < this.itemsLength &&
          this.itemsLength > this.minLimit
          ) {
            this.itemsIndex += this.offSetValue
          } else {
            this.itemsIndex = 0
            this.getdata()
          }
        }, 2000)
      }, this.timeSpan)
    },
    getdata () {
      this.itemsLength = this.sourceLength
    }
  },
  computed: {
    top () {
      return -this.itemsIndex * this.offSetY / this.offSetValue + 'rem'
    },
    transition () {
      return this.itemsIndex === 0 ? 'none' : 'all 0.5s'
    }
  }
}
</script>
<style scoped>
.scroll-content {
  position: relative;
}
</style>
