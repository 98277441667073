const state = {
  rootPassword: 'junfa20201224',
  appSecret: '3fab1e4e-bd45-486f-a5cf-3685dd1fd084',
  studentGrowthTreeSystemIcons: ['assets/images/BuiltIn/StudentGrowTreeIcons/img_level_1.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_2.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_3.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_4.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_5.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_6.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_7.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_8.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_9.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_10.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_11.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_12.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_13.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_14.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_15.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_16.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_17.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_18.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_19.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_20.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_21.png', 'assets/images/BuiltIn/StudentGrowTreeIcons/img_level_22.png'],
  classGrowthTreeSystemIcons: []
}
const getters = {
  rootPassword: (state) => {
    return state.rootPassword
  },
  appSecret: (state) => {
    return state.appSecret
  },
  studentGrowthTreeSystemIcons: (state) => {
    return state.studentGrowthTreeSystemIcons
  },
  classGrowthTreeSystemIcons: (state) => {
    return state.classGrowthTreeSystemIcons
  }
}
const mutations = {
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
