<template>
  <div class="bg-evaluationrecord">
    <div class="bg">
      <div class="head center">
        <div class="adorn"></div>
        <div class="title">评价点滴</div>
        <div class="adorn"></div>
      </div>
      <div class="wrap">
        <ScrollUp :minLimit="5" :offSetY="2.65625" :sourceLength="list.length">
          <div class="content">
          <div v-for="(item,index) of list.concat(
                          list.length > 5
                            ? list.slice(0, 5)
                            : []
                        )" :key="index" class="record-item">
            <img
              class="record-student-img"
              :src="defaultPhoto(item.gender,item.type)"
              v-real-img="item.type===3?defaultPhoto(item.gender,item.type):item.zp"
            />
            <div class="record-student-name">{{item.name}}</div>
            <div class="record-content index">{{item.zbmc}}</div>
            <div class="record-content score">{{item.df}}</div>
            <div class="record-content activity">{{'('+item.hdmc+')'}}</div>
            <div class="record-line"></div>
          </div>
        </div>
        </ScrollUp>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      list: []
    }
  },
  props: {},
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getData()
      setInterval(() => {
        this.getData()
      }, 1000 * 60 * this.$store.getters.dataTimeSpan)
    },
    getData () {
      this.$api.post(
        'v1/classintegrateddata/EvaluationIntravenousDrip',
        {
          SchoolId: this.$store.getters.schoolData.Id,
          SchCode: this.$store.getters.schoolData.Code,
          ClassId: this.$store.getters.classData.Id,
          TermYearStr: this.$store.getters.term.SSXN,
          TermType: this.$store.getters.term.XQLX,
          DataCount: 30
        },
        (r) => {
          const datas = r.Target
          for (const item of datas) {
            let obj
            if (item.BPJDXLX === 1) {
              obj = this.$store.getters.students.find(c => c.Id === item.BPJDXId)
            } else if (item.BPJDXLX === 3) {
              const grade = this.$store.getters.orgsData.find(c => c.ChidOrgList.find(org => org.Id === item.BPJDXId))
              if (grade) {
                obj = grade.ChidOrgList.find(c => c.Id === item.BPJDXId)
              }
            }
            const index = this.$store.getters.indexs.find(c => c.Id === item.ZBId)
            if (obj && index) {
              this.list.push({ name: obj.Name, zp: obj.ZP ? obj.ZP : '', gender: obj.XBM, type: item.BPJDXLX, zbmc: index.IndexName, df: item.DF < 0 ? item.DF + '' : '+' + item.DF, hdmc: item.HDMC })
            }
          }
        }
      )
    }
  },
  computed: {
    defaultPhoto () {
      return (gender, type) => {
        if (type === 3) {
          return require('@/' + this.$store.getters.defaultClassImage)
        } else {
          return require('@/' + this.$store.getters.defaultPhoto(gender))
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-evaluationrecord {
  padding: 1.041667rem 0 0 1.041667rem;
}
.bg {
  width: 30rem;
  height: 20.833333rem;
  background: #ffffff;
  border-radius: 1.5625rem;
}
.head {
  height: 1.875rem;
  padding-top: 1.770833rem;
}
.title {
  height: 1.302083rem;
  font-size: 1.354167rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  margin: 0 .677083rem .572917rem .677083rem;
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 26.875rem;
}
.wrap {
  display: inline-block;
  height: 12.864583rem;
  margin: 2.03125rem 1.5625rem 1.979167rem 1.5625rem;
  overflow: hidden;
}
.record-item {
  // width: 24.166667rem;
  // height: .052083rem;
  // background: #002A7A;
  // opacity: 0.1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: .520833rem;
  position: relative;
}
.record-student-img {
  height: 2.135417rem;
  width: 2.135417rem;
  border-radius: 50%;
  object-fit:cover;
}
.record-student-name{
font-size: 1.041667rem;
font-family: AlibabaPuHuiTiM;
font-weight: 500;
color: #002A7A;
padding-left: .572917rem;
}
.record-content {
font-size: 1.041667rem;
font-family: AlibabaPuHuiTiR;
font-weight: 400;
color: #002A7A;
}
.index {
  padding-left: 1.25rem;
}
.score {
  padding-left: .833333rem;
}
.activity {
  padding-left: 1.614583rem;
}
.record-line{
  position: absolute;
  width: 24.166667rem;
height: .052083rem;
background: #002A7A;
opacity: 0.1;
left: 2.708333rem;
bottom: .364583rem;
}
</style>
