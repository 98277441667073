<template>
  <div class="bg">
    <div class="head">
      <div class="left">
        <SchoolTag v-if="complete" />
        <ClassTag v-if="complete" />
      </div>
      <div class="right">
        <Clock />
        <Date />
      </div>
    </div>
    <div class="content">
      <div>
        <PersonHonor v-if="complete"  :schoolId='schoolId' :schoolCode='schoolCode' :term='term' :classId='classId'/>
        <CourseTable v-if="complete" />
      </div>
      <div>
        <ClassAggregated v-if="complete" />
        <WonderfulMoment v-if="complete" />
      </div>
      <div>
        <ClassHonor v-if="complete" />
        <EvaluationRecord v-if="complete" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SchoolTag from '@/components/SchoolTag'
import ClassTag from '@/components/ClassTag'
import Clock from '@/components/Widgets/Clock'
import Date from '@/components/Widgets/Date'
import PersonHonor from '@/components/PersonHonor'
import ClassAggregated from '@/components/ClassAggregated'
import ClassHonor from '@/components/ClassHonor'
import CourseTable from '@/components/CourseTable'
import WonderfulMoment from '@/components/WonderfulMoment'
import EvaluationRecord from '@/components/EvaluationRecord'
export default {
  components: {
    SchoolTag,
    ClassTag,
    Clock,
    Date,
    PersonHonor,
    ClassAggregated,
    ClassHonor,
    CourseTable,
    WonderfulMoment,
    EvaluationRecord
  },
  data () {
    return {
      schoolCode: '',
      classCode: '',
      schoolId: '',
      classId: '',
      term: {},
      complete: false
    }
  },
  props: {},
  created () {
    this.schoolCode = this.$route.params.code
    this.classCode = this.$route.params.classCode
    this.init()
  },
  methods: {
    ...mapActions([
      'setApiPath',
      'setSchoolData',
      'setOrgsData',
      'setTerm',
      'setStudents',
      'setIndexs'
    ]),
    init () {
      this.getSchoolAndApi().then((r) => {
        if (r) {
          this.getAllOrgs().then((r) => {
            this.getClass().then(() => {
              if (r) {
                this.getTerm().then((r) => {
                  if (r) {
                    this.getStudents().then((r) => {
                      if (r) {
                        this.getIndexs().then((r) => {
                          if (r) {
                            this.complete = true
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
          })
        }
      })
    },
    getSchoolAndApi () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/home/GetSchoolAPIPath',
          {
            SchoolCode: this.schoolCode
          },
          (r) => {
            const schoolApiData = r.Target
            if (schoolApiData.Id && schoolApiData.H5ApiPath) {
              this.schoolId = schoolApiData.Id
              this.setApiPath(schoolApiData.H5ApiPath).then(() => {
                this.setSchoolData({
                  Id: schoolApiData.Id,
                  Name: schoolApiData.Name,
                  Code: schoolApiData.SchoolCode
                }).then(() => {
                  resolve(true)
                })
              })
            } else {
              this.$message.error({
                message: '没有找到api地址，请检查配置',
                duration: 0,
                closable: true
              })
              resolve(false)
            }
          },
          (f) => {
            this.$message.error({
              message: '学校编码错误',
              duration: 0,
              closable: true
            })
            resolve(false)
          },
          this.$store.getters.apipath2
        )
      })
    },
    getClass () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/classintegrateddata/GetClassInfo',
          {
            ClassCode: this.classCode,
            SchoolId: this.schoolId
          },
          (r) => {
            const classData = r.Target
            if (classData) {
              this.classId = classData.Id
              resolve()
            } else {
              resolve(false)
            }
          }
        )
      })
    },
    getAllOrgs () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          {
            SchoolId: this.schoolId
          },
          (r) => {
            const orgsData = r.Target
            if (orgsData) {
              this.setOrgsData({
                orgs: orgsData,
                classCode: this.classCode
              }).then(() => {
                resolve(true)
              })
            } else {
              resolve(false)
            }
          }
        )
      })
    },
    getTerm () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/accountmanage/GetTerm',
          {
            SchoolId: this.schoolId
          },
          (r) => {
            const terms = r.Target
            this.term = terms.find((c) => c.IsCurrent === 1)
            if (this.term) {
              this.setTerm(terms).then(() => {
                resolve(true)
              })
            } else {
              resolve(false)
            }
          }
        )
      })
    },
    getPersonAndGroupSetList () {
      this.$api.post(
        'v1/growthsystem/GetPersonAndGroupSetList',
        {
          SchoolId: this.getters.currentOrgData.SchoolId
        },
        (r) => {
          this.setPersonAndGroupSet(r.Target)
        }
      )
    },
    getIndexs () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/evaluationactivity/GetSchoolEindex',
          {
            SchoolId: this.schoolId,
            IndexType: 0,
            TermYear: this.term.SSXN,
            BeginTime: this.term.BeginTime,
            EndTime: this.term.EndTime
          },
          (r) => {
            this.setIndexs(r.Target).then(() => {
              resolve(true)
            })
          }
        )
      })
    },
    getStudents () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/accountmanage/GetOrgStudent',
          {
            SchoolOrgId: this.$store.getters.classData.Id,
            OrgType: 2,
            SourceType: 1,
            TermYearStr: this.term.SSXN,
            SchoolId: this.schoolId
          },
          (r) => {
            this.setStudents(r.Target).then(() => {
              resolve(true)
            })
          }
        )
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bg {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow: hidden;
  height: 100vh;
  background: url(../assets/images/bakcground.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.bg::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.head {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding: 1.770833rem .9375rem .729167rem .9375rem;
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 4.375rem;
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // padding: .729167rem 1.510417rem 1.5625rem 1.510417rem;
}
.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
