<template>
  <div class="bg-coursetable">
    <div class="bg">
      <div class="head center">
        <div class="adorn"></div>
        <div class="title">今日课表</div>
        <div class="adorn"></div>
      </div>
      <div class="wrap">
        <ScrollUp
          :minLimit="4"
          :offSetY="2.8125"
          :sourceLength="courseTable.length"
        >
          <div class="content">
          <div v-for="(item,index) of courseTable.concat(
                          courseTable.length > 4
                            ? courseTable.slice(0, 4)
                            : []
                        )" :key="index" class="table-row">
            <img class="point" :src="require('@/assets/images/coursetable.png')" alt="coursetable">
            <div class="line"></div>
            <!-- <div class="row-content time">9:00- 9:40</div> -->
            <div class="row-content time">{{'第'+item.jc+'节'}}</div>
            <div class="row-content course">{{item.kc}}</div>
            <div class="row-content teacher">{{item.js}}</div>
          </div>
        </div>
        </ScrollUp>

      </div>
    </div>
  </div>
</template>

<script>
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      courseTable: []
    }
  },
  props: {},
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getCouseTable()
    },
    getCouseTable () {
      this.$api.post(
        'v1/evaluationactivity/GetClassSchedule',
        {
          ClassId: this.$store.getters.classData.Id,
          ClassScheduleType: 2,
          SchoolId: this.$store.getters.schoolData.Id,
          TermId: this.$store.getters.term.Id
        },
        (r) => {
          const datas = r.Target
          if (datas) {
            this.getAllCourse(datas)
          }
        }
      )
    },
    getAllCourse (courseTable) {
      this.$api.post(
        'v1/accountmanage/GetSchoolCurriculum',
        {
          SchoolId: this.$store.getters.schoolData.Id
        },
        (r) => {
          const datas = r.Target
          this.getAllTeachers(courseTable, datas)
        }
      )
    },
    getAllTeachers (courseTable, courses) {
      this.$api.post(
        'v1/accountmanage/GetSchoolTeacher',
        {
          SchoolId: this.$store.getters.schoolData.Id,
          IsDelete: 1
        },
        (r) => {
          const teachers = r.Target
          const xqj = new Date().getDay()
          for (let jc = 1; jc <= courseTable.MaxJC; jc++) {
            const datas = courseTable.ClassScheduleList.find(c => c.XQJ === xqj && c.JC === jc)
            if (datas) {
              const course = courses.find(c => c.Id === datas.CourseId)
              const teacher = teachers.find(c => c.Id === datas.TeacherId)
              if (course && teacher) {
                this.courseTable.push({ date: '', jc: jc, kc: course.Name, js: teacher.Name })
              }
            }
          }
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-coursetable {
  padding-top: 1.041667rem;
}
.bg {
  width: 21.354167rem;
  height: 17.1875rem;
  background: #ffffff;
  border-radius: 1.5625rem;
}
.head {
  height: 1.875rem;
  padding-top: 1.041667rem;
}
.title {
  height: 1.302083rem;
  font-size: 1.354167rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  margin: 0 .677083rem .572917rem .677083rem;
}
.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.wrap {
  display: inline-block;
  height: 9.635417rem;
  margin: 2.1875rem 1.5625rem 0rem 1.5625rem;
  overflow: hidden;
  width: 18.229167rem;
}
.table-row{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 16.979167rem;
  padding-bottom: 1.875rem;
  position: relative;
  height: .9375rem;
}
.row-content{
height: .78125rem;
font-size: .9375rem;
font-family: Microsoft YaHei;
font-weight: 400;
color: #002A7A;
}
.time{
  // width: 5.572917rem;
  width: 2.447917rem;
  padding-left: .9375rem;
}
.course{
  // width: 4.53125rem;
  width: 6.979167rem;
  text-align: right;
}
.teacher{
  width: 5.208333rem;
  text-align: right;
}
.line{
  position: absolute;
    width: .104167rem;
    height: 1.875rem;
    background: #5AA0FF;
    opacity: 0.5;
    left: .3125rem;
    bottom: 2.8125rem;
}
.point{
  width: .729167rem;
  height: .729167rem;
}
</style>
