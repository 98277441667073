<template>
  <div class="bg-personhonor center">
    <div class="head center fullwidth">
      <div class="adorn"></div>
      <div class="title">个人光荣榜</div>
      <div class="adorn"></div>
    </div>
    <div class="wrap fullwidth">
      <el-empty class="fullwidth fullheight" v-if="!list.Orgs||list.Orgs.length===0" description="暂无内容"></el-empty>
      <div
      v-else
        :class="[
          'grade center',
          (index + 1) % 3 !== 0 ? 'border-right' : '',
          parseInt(index / 3) > 0 ? 'border-top' : '',
        ]"
        v-for="(grade, index) in list.Orgs"
        :key="index"
      >
        <div v-if="grade" class="grade-name">{{ grade.Name }}</div>
        <div v-if="grade" class="fullwidth" style="overflow: hidden">
          <ScrollUp
            :minLimit="3"
            :offSetY="2.135417"
            :sourceLength="studentsData(grade).length"
          >
            <div
              class="student-item center"
              v-for="(item, i) in studentsData(grade).concat(
                studentsData(grade).length > 3
                  ? studentsData(grade).slice(0, 3)
                  : []
              )"
              :key="i"
            >
              <div class="student-photo center">
                <img v-real-img="item.Photo" :src="defaultPhoto(item.Gender)" />
                <div class="center student-name">
                  <div class="name-text">{{ item.MemberName }}</div>
                  <div class="class-text">
                    {{
                      grade.ChidOrgList.find((c) => c.Id === item.OrgId)
                        ? grade.ChidOrgList.find((c) => c.Id === item.OrgId)
                            .Name
                        : ""
                    }}
                  </div>
                </div>
              </div>

              <div class="student-content center">
                <img
                  v-for="(growth, j) in item.GrowthSystemList"
                  :key="j"
                  :src="growth.Logo"
                />
              </div>
            </div>
          </ScrollUp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      list: []
    }
  },
  props: {},
  created () {
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/schoolintegrateddata/GetStudentHonrDetail',
          {
            SchoolId: this.$store.getters.schoolData.Id,
            DataCount: 40
          },
          (r) => {
            this.list = r.Target ? r.Target : this.list
            const num =
              this.list.Orgs.length > 0
                ? (parseInt(this.list.Orgs.length / 3) + 1) * 3 -
                  this.list.Orgs.length
                : 0
            for (let i = 0; i < num; i++) {
              this.list.Orgs.push(undefined)
            }
            resolve()
          }
        )
      })
    }
  },
  computed: {
    studentsData () {
      return (grade) => {
        if (grade.ChidOrgList) {
          return this.list.Data.filter((c) =>
            grade.ChidOrgList.map((g) => g.Id).includes(c.OrgId)
          )
        }
        return []
      }
    },
    defaultPhoto () {
      return (gender) => {
        return require('@/' + this.$store.getters.defaultPhoto(gender))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-personhonor {
  margin: 1.5625rem;
  flex-direction: column;
  height: calc(100% - 1.5625rem * 2);
}
.head {
  // height: 1.822917rem;
  // margin-bottom: .78125rem;
  flex: 1;
}
.wrap {
  margin-top: 0.78125rem;
  height: 20.989583rem;
  // flex:1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.grade {
  width: calc(100% / 3 - 4px);
  height: 50%;
  box-sizing: border-box;
  // background: lightcoral;
  border: 0.125rem none #002a7a;
  overflow: hidden;
  justify-content: flex-start;
  flex-direction: column;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  .grade-name {
    background: #f5f6fa;
    border-radius: 0.520833rem;
    padding: 0.78125rem;
    margin-top: 0.520833rem;
    margin-bottom: 0.78125rem;
  }
  .student-item {
    font-size: 1.041667rem;
    font-family: AlibabaPuHuiTiR;
    font-weight: 400;
    height: 1.822917rem;
    margin: 0.3125rem .520833rem;
    justify-content: space-between;
    .student-photo {
      img {
        height: 1.822917rem;
        width: 1.822917rem;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
      }
      .student-name {
        font-size: 0.9375rem;
        margin: 0 0.520833rem;
        flex-direction: column;
        align-items: flex-start;
        .name-text {
        }
        .class-text {
          font-size: 0.729167rem;
          color: #919292;
        }
      }
    }
    .student-content {
      img {
        height: inherit;
        width: 1.5625rem;
        margin: 0 3px;
      }
    }
  }
}
.border-right {
  border-right-style: dotted;
}
.border-top {
  border-top-style: dotted;
}
</style>
