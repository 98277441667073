<template>
  <div class="bg-classhonor center">
    <div class="head center fullwidth">
      <div class="adorn"></div>
      <div class="title">班级光荣榜</div>
      <div class="adorn"></div>
    </div>
    <div class="wrap fullwidth">
      <el-empty
        class="fullwidth fullheight"
        v-if="list.length === 0"
        description="暂无内容"
      ></el-empty>
      <ScrollUp :minLimit="5" :offSetY="4.0625" :sourceLength="list.length">
        <div
          class="item center"
          v-for="(item, index) of list.concat(
            list.length > 5 ? list.slice(0, 5) : []
          )"
          :key="index"
        >
          <div class="text">
            {{
              orgs
                .filter((c) => c.Id === item.BJId)
                .map((c) => c.Name)
                .join("")
            }}
          </div>
          <div class="center icon">
            <div
              class="basic-score"
              v-for="tx in item.DX"
              :key="tx.CZTXId"
            >
              <img
                :class="tx.ZCId === lastWeek ? '' : 'decolorize'"
                :src="tx.TB"
              />
              X{{ tx.DXS }}
            </div>
          </div>
        </div>
      </ScrollUp>
    </div>
  </div>
</template>

<script>
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      list: [],
      frequency: [],
      orgs: [],
      lastWeek: ''
    }
  },
  props: {},
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getOrgs().then(() => {
        this.getFrequency().then(() => {
          this.getLastWeek().then(() => {
            this.getData()
          })
        })
      })
    },
    getFrequency () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/schoolintegrateddata/GetSystemFrequency',
          {
            SchoolId: this.$store.getters.schoolData.Id,
            TermId: this.$store.getters.term.Id
          },
          (r) => {
            this.frequency = r.Target ? r.Target : this.frequency
            resolve()
          }
        )
      })
    },
    getData () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/schoolintegrateddata/GetClassHonrDetailNew',
          {
            SchoolId: this.$store.getters.schoolData.Id,
            TermYearStr: this.$store.getters.term.SSXN,
            TermType: this.$store.getters.term.XQLX
          },
          (r) => {
            r.Target.forEach(item => {
              if (item.DX && typeof item.DX === 'string') {
                item.DX = JSON.parse(item.DX)
              }
            })
            this.list = r.Target ? r.Target : this.list
            resolve()
          }
        )
      })
    },
    getOrgs () {
      return new Promise((resolve) => {
        const data = this.$store.getters.orgsData().map((c) => c.ChidOrgList)
        this.orgs = data.reduce((prev, item) => {
          return prev.concat(item)
        }, [])
        resolve()
      })
    },
    getLastWeek () {
      return new Promise((resolve) => {
        const maxPeriodNum = Math.max(
          ...this.frequency.map((c) => c.PeriodNum)
        )
        const last = this.frequency.find((c) => c.PeriodNum === maxPeriodNum)
        this.lastWeek = last ? last.WeekSetId : ''
        resolve()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-classhonor {
  margin: 1.5625rem 0.5rem;
  flex-direction: column;
  height: calc(100% - 1.5625rem * 2);
}
.head {
  // height: 1.822917rem;
  // margin-bottom: .78125rem;
  flex: 1;
}
.wrap {
  height: 15.364583rem;
  margin-top: 0.78125rem;
  // flex:1;
  overflow: hidden;
  .item {
    height: 3.177083rem;
    background: #f5f6fa;
    border-radius: 0.520833rem;
    padding: 0 0.78125rem;
    justify-content: space-between;
    width: initial;
    .text {
      min-width: 8.072917rem;
      font-size: 1.145833rem;
      font-family: AlibabaPuHuiTiM;
      font-weight: 500;
      color: #002a7a;
    }
    .icon {
      flex: 1;
      justify-content: space-between;
      padding-left: 0.520833rem;
      .basic-score {
        font-size: 1.145833rem;
        font-family: AlibabaPuHuiTiM;
        font-weight: 500;
        color: #002a7a;
        img {
          width: 1.145833rem;
          height: 1.145833rem;
        }
      }
      .promotion-score {
        width: 1.145833rem;
        height: 1.145833rem;
        img {
          width: inherit;
        }
      }
    }
  }
  .item + .item {
    margin-top: 0.885417rem;
  }
}
</style>
