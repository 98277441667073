<template>
  <div class="center">
    <div class="wicon"></div>
    <div class="label">雷 16℃~20℃</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.wicon {
  width: 2.708333rem;
  height: 2.083333rem;
  background: url(../../../assets/images/weather.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.label {
  font-size: 1.145833rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #ffffff;
  padding: .78125rem 0 .052083rem .989583rem;
}
</style>
