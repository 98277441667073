<template>
  <div class="center school">
    <div class="head fullwidth">
      <SchoolTag v-if="complete" />
      <div class="center">
        <Clock />
        <Date />
      </div>
    </div>
    <div class="content fullwidth center">
      <div class="left center fullheight">
        <div class="top fullwidth center">
          <div class="top-left center fullheight">
            <div class="module evaluationcount fullwidth">
              <EvaluationCount v-if="complete" />
            </div>
            <div class="module classhonor fullwidth"><ClassHonor v-if="complete" /></div>
          </div>
          <div class="module quality fullheight"><Quality v-if="complete" /></div>
        </div>
        <div class="module wonderfulmoment fullwidth"><WonderfulMonment v-if="complete" /></div>
      </div>
      <div class="right center fullheight">
        <div class="module personhonor fullwidth"><PersonHonor v-if="complete" /></div>
        <div class="module evaluationrecord fullwidth"><EvaluationRecord v-if="complete" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SchoolTag from '@/components/SchoolTag'
import ClassHonor from '@/components/ClassHonorBySchool'
import Quality from '@/components/Quality'
import WonderfulMonment from '@/components/WonderfulMonmentBySchool'
import EvaluationCount from '@/components/EvaluationCount'
import PersonHonor from '@/components/PersonHonorBySchool'
import EvaluationRecord from '@/components/EvaluationRecordBySchool'
import Clock from '@/components/Widgets/Clock'
import Date from '@/components/Widgets/Date'
export default {
  components: {
    SchoolTag,
    Clock,
    Date,
    EvaluationCount,
    ClassHonor,
    Quality,
    WonderfulMonment,
    PersonHonor,
    EvaluationRecord
  },
  data () {
    return {
      schoolCode: '',
      complete: false
    }
  },
  props: {},
  mounted () {
    this.schoolCode = this.$route.params.code
    this.init()
  },
  methods: {
    ...mapActions([
      'setApiPath',
      'setSchoolData',
      'setOrgsData',
      'setTerm'
      // 'setStudents',
      // 'setIndexs'
    ]),
    init () {
      this.complete = false
      this.getSchoolAndApi().then((r) => {
        if (r) {
          this.getOrgs().then(r => {
            if (r) {
              this.getCurrentTerm().then(r => {
                if (r) {
                  this.complete = true
                }
              })
            }
          })
        }
      })
    },
    getSchoolAndApi () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/home/GetSchoolAPIPath',
          {
            SchoolCode: this.schoolCode
          },
          (r) => {
            const schoolApiData = r.Target
            if (schoolApiData.Id && schoolApiData.H5ApiPath) {
              this.setApiPath(schoolApiData.H5ApiPath).then(() => {
                this.setSchoolData({
                  Id: schoolApiData.Id,
                  Name: schoolApiData.Name,
                  Code: schoolApiData.SchoolCode
                }).then(() => {
                  resolve(true)
                })
              })
            } else {
              this.$message({
                type: 'error',
                message: '没有找到api地址，请检查配置',
                duration: 2000,
                closable: true
              })
              resolve(false)
            }
          },
          (f) => {
            this.$message({
              type: 'error',
              message: '没有找到api地址，请检查配置',
              duration: 2000,
              closable: true
            })
            resolve(false)
          },
          this.$store.getters.apipath2
        )
      })
    },
    getOrgs () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolOrg',
          {
            SchoolId: this.$store.getters.schoolData.Id
          },
          (r) => {
            const data = r.Target
            if (data) {
              this.setOrgsData({
                orgs: data,
                classCode: undefined
              }).then(() => {
                resolve(true)
              })
            } else {
              resolve(false)
            }
          }
        )
      })
    },
    getCurrentTerm () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/home/GetCurrentTerm',
          {
            SchoolId: this.$store.getters.schoolData.Id
          },
          (r) => {
            const data = r.Target
            if (data) {
              this.setTerm(data).then(() => {
                resolve(true)
              })
            } else {
              resolve(false)
            }
          }
        )
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.school {
  flex-direction: column;
  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 4.375rem;
  }
  .content {
    flex: 1;
    .left {
      width: 45%;
      flex-direction: column;
      .top {
        height: 28.125rem;
        .top-left {
          flex-direction: column;
          width: 18.229167rem;
          .evaluationcount {
            height: 6.25rem;
          }
          .classhonor {
            margin-top: 1.041667rem;
            flex: 1;
          }
        }
        .quality {
          flex: 1;
          margin-left: 1.041667rem;
        }
      }
      .wonderfulmoment {
        margin-top: 1.041667rem;
        flex: 1;
      }
    }
    .right {
      flex: 1;
      margin-left: 1.041667rem;
      flex-direction: column;
      .personhonor {
        // height: 55%;
        height: 26.614583rem;
      }
      .evaluationrecord {
        margin-top: 1.041667rem;
        flex: 1;
      }
    }
  }
}
</style>
