import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import api from './utils/axios'
import './utils/directive'
import './utils/filter'
import './assets/style/reset.css'
import './assets/style/font.css'
import './assets/style/common.css'
import * as echarts from 'echarts'
import { Tag, Badge, Empty } from 'element-ui'
Vue.use(Tag)
Vue.use(Badge)
Vue.use(Empty)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
