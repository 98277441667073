import Vue from 'vue'
import { imageIsExist } from './common'
Vue.directive('real-img', async function (el, binding) {
  console.log(el, binding, 'binding')
  console.log(el.dataset, 'datadset')
  const imgURL = binding.value
  if (imgURL) {
    const exist = await imageIsExist(imgURL)
    if (exist) {
      el.setAttribute('src', imgURL)
    }

    if (!exist && el.dataset.type) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
})
