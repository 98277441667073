<template>
  <div class="bg-classaggregated">
    <div class="bg">
      <div class="head center">
        <div class="adorn"></div>
        <div class="title">快乐成长</div>
        <div class="adorn"></div>
      </div>
      <div class="wrap">
        <ScrollUp :minLimit="5" :offSetY="3.4375" :sourceLength="Math.floor(studentData.length/5)">
          <div class="content">
            <div
              :class="['student', (index+1) % 5 === 1 ? '' : 'right']"
              v-for="(item,index) in list"
              :key="index"
            >
              <img
                class="student-photo"
                :src="defaultPhoto(item.XBM)"
                :alt="item.Name"
                v-real-img="item.ZP"
              />
              <!-- <img v-if="item.WeekStars===1" :class="['student-promotion', (index+1) % 5 === 1 ? '' : 'right']" :src="require('@/assets/images/promotion.png')"> -->
              <div class="student-name">{{item.Name}}</div>
              <img
                v-if="item.JJTB"
                :class="['student-score']"
                :src="item.TBLX===1?item.JJTB:require('@/' + item.JJTB)"
                alt=""
              />
            </div>
          </div>
        </ScrollUp>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      growthsystem: [],
      reportData: [],
      studentData: [],
      PromotionStar: Infinity,
      schoolDetail: undefined
    }
  },
  props: {},
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getData()
      setInterval(() => {
        this.getData()
      }, 1000 * 60 * this.$store.getters.dataTimeSpan)
    },
    getSchoolDetail () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/accountmanage/GetSchoolMessage',
          {
            SchoolId: this.$store.getters.schoolData.Id
          },
          (r) => {
            const data = r.Target
            if (data) {
              this.schoolDetail = data
            }
            resolve()
          }
        )
      })
    },
    getData () {
      this.getSchoolDetail().then(() => {
        this.$api.post(
          'v1/accountmanage/GetSchoolPersonClassTree',
          {
            SchoolId: this.$store.getters.schoolData.Id,
            GrowthTreeType: 1,
            IsAll: 2
          },
          (r) => {
            const data = r.Target
            if (data) {
              const systemIcons = this.$store.getters.studentGrowthTreeSystemIcons
              let index = 0
              for (const item of data.filter(c => c.ZSLX === 1)) {
                item.ZDYTB = systemIcons[index] ? systemIcons[index] : undefined
                index++
              }
              this.growthsystem = data.filter(c => c.ZSLX === 1)
            }
            this.getStudent()
          }
        )
      })
    },
    getStudent () {
      this.studentData = JSON.parse(JSON.stringify(this.$store.getters.students))
      this.getReportData()
    },
    getReportData () {
      this.$api.post(
        'v1/preview/StudentComprehensiveReport',
        {
          ClassId: this.$store.getters.classData.Id,
          TermYearStr: this.$store.getters.term.SSXN,
          TermType: this.$store.getters.term.XQLX
        },
        (r) => {
          if (r.Code === 0) {
            this.reportData = r.Target
            this.studentData.map((c) => {
              const data = this.reportData.find(
                (item) => item.StudentId === c.Id
              )
              c.Stars = data ? data.Score : 0
              const systems = this.growthsystem
                .filter((item) => item.FS <= c.Stars)
                .sort((a, b) => {
                  return b.FS - a.FS
                })
              c.JJTB = systems[0] ? (this.schoolDetail && this.schoolDetail.GRCZSZDYTB === 1 ? systems[0].ZSLJ : systems[0].ZDYTB) : this.$store.getters.studentGrowthTreeSystemIcons[0]
              c.TBLX = systems[0] ? (this.schoolDetail && this.schoolDetail.GRCZSZDYTB === 1 ? 1 : 2) : 2
              return c
            })
            this.$set(
              this,
              'studentData',
              JSON.parse(JSON.stringify(this.studentData))
            )
            this.studentData = this.studentData.sort(this.compare)
          }
        }
      )
    },
    compare (x, y) {
      if (x.Stars < y.Stars) {
        return 1
      } else if (x.Stars > y.Stars) {
        return -1
      } else {
        return 0
      }
    }
  },
  computed: {
    list () {
      return this.studentData.concat(
        this.studentData.length > 30
          ? this.studentData.slice(0, 30)
          : []
      )
    },
    defaultPhoto () {
      return (gender) => {
        return require('@/' + this.$store.getters.defaultPhoto(gender))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-classaggregated {
  padding-left: 1.041667rem;
}
.bg {
  width: 43.4375rem;
  height: 26.666667rem;
  background: #ffffff;
  border-radius: 1.5625rem;
}
.head {
  height: 1.875rem;
  padding-top: 1.770833rem;
}
.content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top:.520833rem
  // padding: 2.03125rem 1.5625rem 1.979167rem 1.5625rem;
}
.wrap {
  display: inline-block;
  height: 20.15625rem;
  margin: 2.03125rem 1.5625rem 0rem 1.5625rem;
  overflow: hidden;
}
.title {
  height: 1.302083rem;
  font-size: 1.354167rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
  margin: 0 .677083rem .572917rem .677083rem;
}
.student {
  width: 6.979167rem;
  height: 2.03125rem;
  display: flex;
  align-items: center;
  padding-bottom: 1.40625rem;
  position: relative;
}
.right {
  padding-left: 1.302083rem;
}
.student-photo {
  height: 2.03125rem;
  width: 2.03125rem;
  border-radius: 50%;
}
.student-name {
  width: 3.125rem;
  font-size: .989583rem;
  font-family: AlibabaPuHuiTiR;
  font-weight: 500;
  color: #002a7a;
  padding-left: .46875rem;
}
.student-score {
  height: 1.09375rem;
  width: 1.09375rem;
  padding-left: .3125rem;
}
.student-promotion{
  width: .833333rem;
  height: .833333rem;
  position: absolute;
    top: -0.260417rem;
    left: 1.458333rem;
}
</style>
