import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
// import createPersistedState from 'vuex-persistedstate'
// const dataState = createPersistedState({})
Vue.use(Vuex)
const store = new Vuex.Store({
  mutations: {
  },
  actions: {
  },
  modules
  // plugins: [dataState]
})
export default store
