<template>
  <div class="bg-personhonor">
    <div class="bg">
      <div class="wrap">
        <ScrollUp
          ref="scroll"
          :minLimit="5"
          :offSetY="4.21875"
          :sourceLength="students.length"
        >
          <div v-for="(item,index) in students.concat(
                          students.length > 5
                            ? students.slice(0, 5)
                            : []
                        )" :key="index" class="studentBG center">
            <div class="rank">{{ item.Rank }}</div>
            <div class="student">
              <div v-if="item.Rank<= 3" :class="'boder-rank' + item.Rank"></div>
              <img
                class="student-photo"
                :src="defaultPhoto(item.XBM)"
                :alt="item.Name"
                v-real-img="item.ZP"
              />
            </div>
            <div class="student-name">{{item.Name}}</div>
            <div class="student-score">{{ item.DF>0?'+'+item.DF:item.DF }}</div>
          </div>
        </ScrollUp>
      </div>
      <div class="tabbtn">
        <div
          class="btn"
          :class="isWeek === undefined ? '' : isWeek ? 'btn-left' : 'btn-right'"
        ></div>
        <div class="tab-text center">
          <div
            class="tab-text-content center"
            :class="
              isWeek === undefined ? 'font-white' : isWeek ? 'font-white' : ''
            "
            @click="getData(true)"
          >
            本周
          </div>
          <div
            class="tab-text-content center"
            :class="isWeek === undefined ? '' : !isWeek ? 'font-white' : ''"
            @click="getData(false)"
          >
            本期
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollUp from '@/components/Scroll/ScrollUp'
export default {
  components: {
    ScrollUp
  },
  data () {
    return {
      isWeek: undefined,
      students: []
    }
  },
  props: {
    term: {
      type: Object
    },
    classId: {
      type: String
    },
    schoolCode: {
      type: String
    },
    schoolId: {
      type: String
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.$store.getters.term) {
        this.getData()
        setInterval(() => {
          this.getData()
        }, 1000 * 60 * this.$store.getters.dataTimeSpan)
      }
    },
    getData (isWeek) {
      let startDate = ''
      let endDate = ''
      const now = new Date()
      if (isWeek) {
        const term = this.term
        const weekList = term.weekList
        let curWeek = weekList.find(week => {
          const BeginTime = new Date(week.BeginTime)
          const EndTime = new Date(week.EndTime)
          return BeginTime <= now && EndTime >= now
        })

        if (curWeek) {
          startDate = curWeek.BeginTime
          endDate = curWeek.EndTime
        } else {
          if (now > new Date(term.EndTime)) {
            curWeek = weekList.find(week => week.EndTime === term.EndTime)
          }
          if (now < new Date(term.BeginTime)) {
            curWeek = weekList.find(week => week.BeginTime === term.BeginTime)
          }
          startDate = curWeek.BeginTime || ''
          endDate = curWeek.EndTime || ''
        }
      } else {
        const { BeginTime, EndTime } = this.term
        startDate = BeginTime
        endDate = EndTime
      }
      this.isWeek = isWeek

      this.$api.post(
        '/v1/ReportCenter/GetTotalAnnouncement',
        {
          ssxx: this.schoolId, // 是 string 所属学校Id
          xqid: this.term.Id, // 是 string 学期Id
          ssxn: this.term.SSXN, // 是 string 所属学年
          xxbm: this.schoolCode, // 是 string 学校编码
          xqlx: this.term.XQLX, // 是 int 学期类型
          bpjdxlx: 1, // 是 int 被评价对象类型
          kcid: undefined, // 否 string 课程id
          bjid: this.classId, // 否 string 班级id
          hdid: undefined, // 否 string 活动id
          hdlx: undefined, // 否 int 活动类型
          startDate, // 否 date 开始日期
          endDate // 否 date 结束日期
        },
        (r) => {
          const datas = r.Target
          this.students = JSON.parse(JSON.stringify(this.$store.getters.students))
          for (const item of this.students) {
            const data = datas.find(c => c.DXId === item.Id)
            item.DF = data ? isNaN(Number(data.DF)) ? 0 : Number(data.DF) : 0
          }
          this.students = this.students.sort(this.compare)
          let rank = 1
          for (const item of this.students) {
            item.Rank = rank
            rank++
          }
          if (isWeek !== undefined) {
            this.$refs.scroll.itemsIndex = 0
          }
        }
      )
    },
    compare (x, y) {
      const a = isNaN(x.DF) ? 0 : Number(x.DF)
      const b = isNaN(y.DF) ? 0 : Number(y.DF)
      if (a < b) {
        return 1
      } else if (a > b) {
        return -1
      } else {
        return 0
      }
    }
  },
  computed: {
    defaultPhoto () {
      return (gender) => {
        return require('@/' + this.$store.getters.defaultPhoto(gender))
      }
    }
  },
  watch: {
    term: {
      handler (val) {
        this.getData(this.isWeek)
      },
      deep: true
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-personhonor{
}
.bg {
  width: 21.359375rem;
  height: 30.3125rem;
  background: url(../../assets/images/personhonor.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.wrap {
  display: inline-block;
  margin: 5.625rem 1.5625rem 0 1.5625rem;
  height: 20.520833rem;
  width: 18.229167rem;
  overflow: hidden;
}
.tabbtn {
  margin: 1.145833rem 7.03125rem 1.197917rem 7.03125rem;
  width: 7.291667rem;
  height: 1.770833rem;
  background: #ffffff;
  border-radius: .520833rem;
  position: relative;
}
.studentBG {
  width: 18.229167rem;
  height: 3.645833rem;
  background: url(../../assets/images/studentBG.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: .572917rem;
  justify-content: space-evenly;
}
.rank {
  width: .416667rem;
  font-size: 1.041667rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  font-style: italic;
  color: #f25027;
}
.boder-rank1 {
  position: relative;
  top: -0.260417rem;
  width: 3.697917rem;
  height: 3.697917rem;
  background: url(../../assets/images/border1.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 10;
}
.boder-rank2 {
  position: relative;
  top: -0.260417rem;
  width: 3.697917rem;
  height: 3.697917rem;
  background: url(../../assets/images/border2.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 10;
}
.boder-rank3 {
  position: relative;
  top: -0.260417rem;
  width: 3.697917rem;
  height: 3.697917rem;
  background: url(../../assets/images/border3.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 10;
}
.student-name {
  font-size: 1.041667rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
}
.student-photo {
  position: absolute;
  top: .625rem;
  left: .677083rem;
  width: 2.34375rem;
  height: 2.34375rem;
  border-radius: 50%;
  object-fit:cover;
}
.student {
  margin-left: .833333rem;
  position: relative;
  width: 3.697917rem;
  height: 3.697917rem;
}
.tab-text {
  // position: relative;
  width: 100%;
  height: 100%;
}
.tab-text-content{
  width: 50%;
  height: 100%;
  font-size: .78125rem;
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #002a7a;
  z-index: 10;
  cursor: pointer;
}
.tab-text-week {
  display: inline-block;
  width: 1.614583rem;
  height: .78125rem;
  font-size: .78125rem;
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #002a7a;
  margin: .520833rem 0 .46875rem 1.041667rem;
  z-index: 10;
  cursor: pointer;
}
.tab-text-term {
  display: inline-block;
  width: 1.614583rem;
  height: .78125rem;
  font-size: .78125rem;
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #002a7a;
  margin: .520833rem 1.041667rem .46875rem 1.979167rem;
  z-index: 10;
  cursor: pointer;
}
.font-white {
  color: #f5f6fa;
}
.student-score {
  margin-left: 3.90625rem;
  width: 1.770833rem;
  height: .78125rem;
  font-size: 1.041667rem;
  font-family: AlibabaPuHuiTiM;
  font-weight: 500;
  color: #002a7a;
}
.btn {
  position: absolute;
  width: 3.645833rem;
  height: 1.5625rem;
  background: #f25027;
  border-radius: .520833rem;
  top: .104167rem;
  left: .104167rem;
}
.btn-right {
  left: 3.541667rem;
  animation: animation-right 0.5s;
  -webkit-animation: animation-right 0.5s;
}
.btn-left {
  left: .104167rem;
  animation: animation-left 0.5s;
  -webkit-animation: animation-left 0.5s;
}
@keyframes animation-left {
  0% {
    left: 3.541667rem;
  }
  10% {
    left: .104167rem;
  }
}
@-webkit-keyframes animation-left {
  0% {
    left: 3.541667rem;
  }
  10% {
    left: .104167rem;
  }
}
@keyframes animation-right {
  0% {
    left: .104167rem;
  }
  10% {
    left: 3.541667rem;
  }
}
@-webkit-keyframes animation-right {
  0% {
    left: .104167rem;
  }
  10% {
    left: 3.541667rem;
  }
}
</style>
