import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/class/:code/:classCode',
    name: 'Class',
    component: require('@/views/Class').default
  },
  {
    path: '/school/:code',
    name: 'School',
    component: require('@/views/School').default
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
