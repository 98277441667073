<template>
  <div class="center">
    <div class="logo"></div>
    <div class="label">{{$store.getters.schoolData.Name}}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.logo {
  width: 2.916667rem;
  height: 2.916667rem;
  background: url(../../assets/images/logo.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-left: .520833rem;
}
.label {
  height: 1.40625rem;
  font-size: 1.458333rem;
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.510417rem;
  margin: .729167rem 1.822917rem .78125rem 1.09375rem;
}
</style>
