<template>
<div class="app fullheight">
    <router-view class="container"></router-view>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss" scoped>
.app{
  background: url(./assets/images/bakcground.png) no-repeat;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}
.container{
  padding: 1.770833rem 1.5625rem 1.5625rem 1.5625rem;
  flex: 1;
}
</style>
