<template>
  <div class="bg-wonderfulmoment center">
    <div class="head center fullwidth">
      <div class="adorn"></div>
      <div class="title">精彩瞬间</div>
      <div class="adorn"></div>
    </div>
    <div class="wrap fullwidth">
      <el-empty class="fullheight fullheight" v-if="list.length===0" description="暂无内容"></el-empty>
      <ScrollLeft
      v-else
        class="fullheight"
        :minLimit="3"
        :offSetX="14.479167"
        :sourceLength="list.length"
      >
        <div class="center content">
          <div
            v-for="(item,index) in list.concat(list.length > 3 ? list.slice(0, 3) : [])"
            :key="index"
            class="item center"
          >
            <img :src="item.Path" v-real-img='item.Path'/>
          </div>
        </div>
      </ScrollLeft>
    </div>
  </div>
</template>

<script>
import ScrollLeft from '@/components/Scroll/ScrollLeftBySchool'
export default {
  components: {
    ScrollLeft
  },
  data () {
    return {
      weekNumber: 0,
      list: []
    }
  },
  props: {},
  created () {
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve) => {
        this.$api.post(
          'v1/schoolintegrateddata/WonderfulMoment',
          {
            SchoolId: this.$store.getters.schoolData.Id,
            DataCount: 40
          },
          (r) => {
            this.list = r.Target ? r.Target : this.list
            resolve()
          }
        )
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-wonderfulmoment {
  margin: 1.5625rem;
  flex-direction: column;
  height: calc(100% - 1.5625rem * 2);
}
.head {
  height: 1.822917rem;
  margin-bottom: 0.78125rem;
}
.wrap {
  flex: 1;
  overflow: hidden;
  width: 43.385417rem;
  .content {
    width: inherit;
    height: 100%;
    justify-content: flex-start;
  }
  .item {
    width: 13.4375rem;
    padding: 0.520833rem;
    height: 100%;
    img {
      border-radius: 0.520833rem;
      width: inherit;
      // height: 100%;
      height: 12.604167rem;
      background-repeat: no-repeat;
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
    }
  }
}
</style>
